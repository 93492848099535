<template>
  <v-container class="text-center">
    <v-row justify="center">
      <v-col cols="12">
        <h1>Welcome to Byteblinx</h1>
        <the-logo></the-logo>
        <p>
          Discover customised software solutions from Byteblinx, where
          innovative technology meets creative passion. We strive to develop
          software that does more than just work - it should inspire and excite.
        </p>
        <p>
          Whether you are a start-up, a small business or an established
          company, Byteblinx is your trusted partner for digital solutions that
          deliver real value. We not only master the art of coding, but also
          understand your individual needs.
        </p>
      </v-col>
    </v-row>

    <service-view></service-view>
    <about-view></about-view>
  </v-container>
</template>

<script lang="ts">
import TheLogo from "@/components/TheLogo.vue";
import ServiceView from "@/views/ServiceView.vue";
import { defineComponent } from "vue";
import AboutView from "@/views/AboutView.vue";
import { VContainer, VRow, VCol } from "vuetify/components";

export default defineComponent({
  components: {
    TheLogo,
    ServiceView,
    AboutView,
    VContainer,
    VRow,
    VCol,
  },
  name: "HomeView",
  methods: {
    goToServices() {
      this.$router.push("/service");
    },
  },
});
</script>

<style>
/* Eigene Styles anpassen oder durch Vuetify-Stile ersetzen. */
/* Vuetify bietet viele Hilfsklassen, die einige der folgenden Definitionen ersetzen könnten. */
</style>

<template>
  <v-container>
    <v-row>
      <v-col
        cols="12"
        sm="6"
        md="4"
        v-for="service in services"
        :key="service.id"
      >
        <v-card class="mx-auto" max-width="344">
          <v-img
            class="white--text"
            height="200px"
            :src="service.image"
            :alt="service.title"
            cover
          >
          </v-img>
          <v-card-title>{{ service.title }}</v-card-title>
          <v-card-text>
            <p>{{ service.description }}</p>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import {
  VContainer,
  VRow,
  VCol,
  VCard,
  VImg,
  VCardTitle,
  VCardText,
} from "vuetify/components";

export default defineComponent({
  name: "ServicesView",
  components: {
    VContainer,
    VRow,
    VCol,
    VCard,
    VImg,
    VCardTitle,
    VCardText,
  },
  data() {
    return {
      services: [
        {
          id: 1,
          title: "Desktop Applications",
          image: require("../assets/service-images/desktop-app.png"),
          description:
            "Development of customised desktop software solutions that are tailored to efficiency, performance and user-friendliness. Utilising the latest technologies for future-proof and integrable systems.",
        },
        {
          id: 2,
          title: "API & Microservices",
          image: require("../assets/service-images/api.png"),
          description:
            "Creation of scalable, secure APIs, supported by microservices architectures and Docker containers. Flexible, maintainable and efficiently scalable solutions.",
        },
        {
          id: 3,
          title: "Software Consulting",
          image: require("../assets/service-images/consulting.png"),
          description:
            "Advice on modern software architectures and best practices. Help in aligning your software strategy with the latest technology trends.",
        },
        {
          id: 4,
          title: "Maintenance & Support",
          image: require("../assets/service-images/support.png"),
          description:
            "Reliable maintenance and support using the latest tools and techniques. Ensuring the continuous performance and security of your applications.",
        },
        {
          id: 5,
          title: "Cloud Services",
          image: require("../assets/service-images/cloud.png"),
          description:
            "Development and management of cloud-based applications and infrastructures. Expertise in cloud migration, management and optimisation.",
        },
      ],
    };
  },
});
</script>

<style scoped></style>

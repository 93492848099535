import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_img = _resolveComponent("v-img")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_container = _resolveComponent("v-container")!

  return (_openBlock(), _createBlock(_component_v_container, null, {
    default: _withCtx(() => [
      _createVNode(_component_v_row, null, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.services, (service) => {
            return (_openBlock(), _createBlock(_component_v_col, {
              cols: "12",
              sm: "6",
              md: "4",
              key: service.id
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_card, {
                  class: "mx-auto",
                  "max-width": "344"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_img, {
                      class: "white--text",
                      height: "200px",
                      src: service.image,
                      alt: service.title,
                      cover: ""
                    }, null, 8, ["src", "alt"]),
                    _createVNode(_component_v_card_title, null, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(service.title), 1)
                      ]),
                      _: 2
                    }, 1024),
                    _createVNode(_component_v_card_text, null, {
                      default: _withCtx(() => [
                        _createElementVNode("p", null, _toDisplayString(service.description), 1)
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  _: 2
                }, 1024)
              ]),
              _: 2
            }, 1024))
          }), 128))
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
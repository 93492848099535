import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import "./style/global-style.css";

// Vuetify-Spezifische Imports
import { createVuetify } from "vuetify";
import "vuetify/styles"; // Global CSS hat jetzt Vuetify-Stile
import "@mdi/font/css/materialdesignicons.min.css";

const vuetify = createVuetify({
  // Konfiguration von Themes
  theme: {
    defaultTheme: "dark", // Setzt das Standard-Theme auf Dark
    themes: {
      dark: {
        // Hier können Sie Ihre eigenen Farben für das Dark-Theme definieren,
        // falls Sie individuelle Anpassungen vornehmen möchten.
        // Beispiel:
        // primary: '#...',
        // secondary: '#...',
        // accent: '#...',
        // error: '#...',
        // info: '#...',
        // success: '#...',
        // warning: '#...'
      },
      // Sie können hier auch ein Light-Theme definieren,
      // das bei Bedarf umgeschaltet werden kann.
    },
  },
});

createApp(App)
  .use(router)
  .use(vuetify) // Vuetify als Plugin hinzufügen
  .mount("#app");

<template>
  <v-container class="text-center">
    <v-row justify="center">
      <v-col cols="12">
        <img
          src="../assets/Byteblinx-for-dark.png"
          alt="Byteblinx Logo"
          width="30%"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { VContainer, VRow, VCol } from "vuetify/components";

export default defineComponent({
  name: "TheLogo",
  components: {
    VContainer,
    VRow,
    VCol,
  },
});
</script>

<style></style>

<template>
  <v-footer class="text-center d-flex flex-column">
    <!-- Icons und Links zu Social Media -->
    <div>
      <v-btn
        v-for="(link, icon) in socialMediaLinks"
        :key="icon"
        class="mx-4"
        icon
        :href="link"
        target="_blank"
      >
        <v-icon>{{ icon }}</v-icon>
      </v-btn>
    </div>

    <!-- Zusätzlicher Text -->
    <div class="pt-0">Innovation, Quality in Each Code Stroke.</div>

    <v-divider></v-divider>

    <!-- Footer Copyright -->
    <div>
      © {{ new Date().getFullYear() }} <strong>Byte</strong>blinx. All rights
      reserved.
    </div>
  </v-footer>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { VFooter, VBtn, VIcon, VDivider } from "vuetify/components";

export default defineComponent({
  name: "TheFooter",
  components: {
    VFooter,
    VBtn,
    VIcon,
    VDivider,
  },
  setup() {
    // Social Media Links mit zugehörigen Icons
    const socialMediaLinks = {
      "mdi-github": "https://github.com/byteblinx",
      "mdi-twitter": "https://twitter.com/byteblinx",
      "mdi-instagram": "https://instagram.com/byteblinx",
    };

    return { socialMediaLinks };
  },
});
</script>

<style scoped>
/* Hier können Sie bei Bedarf weitere Styles hinzufügen */
</style>

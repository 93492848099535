<template>
  <v-app>
    <v-main>
      <home-view></home-view>
    </v-main>
    <the-footer></the-footer>
  </v-app>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import TheFooter from "@/components/TheFooter.vue";
import HomeView from "@/views/HomeView.vue";
import { VApp, VMain } from "vuetify/components";

export default defineComponent({
  name: "App",
  components: {
    HomeView,
    TheFooter,
    VApp,
    VMain,
  },
  metaInfo: {
    script: [
      {
        type: "application/ld+json",
        json: {
          "@context": "http://schema.org",
          "@type": "Organization",
          name: "Byteblinx",
          url: "http://www.byteblinx.com",
        },
      },
    ],
  },
});
</script>

<style>
#app {
  font-family: "Montserrat", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
